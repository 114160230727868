const diff = require('deep-diff')
const get = require('lodash/get')
const set = require('lodash/set')
const takeWhile = require('lodash/takeWhile')

const clone = o => JSON.parse(JSON.stringify(o))

// Calculatete difference (i.e. Union - Intersection) between 2 objects
const objectDiff = (initial, actual) => {
  if (initial === null) {
    return null
  }
  if (actual === null) {
    return null
  }
  // https://www.npmjs.com/package/deep-diff
  let d = diff(initial, actual)

  if (!d) {
    return null
  }
  // console.log(d)

  const expandArray = (memo, path) => {
    // we are dealing with arrays and possibly array of arrays so we must first make
    // a deep copy of a rich enough context

    if (path.every(index => typeof index === 'string')) {
      return memo
    }
    let contextPath = takeWhile(path, p => typeof p === 'string').join('.')
    if (!get(memo, contextPath)) {
      set(memo, contextPath, clone(get(initial, contextPath)))
    }
    return memo
  }

  return d.reduce((memo, {kind, path, index, rhs}) => {
    memo = expandArray(memo, path)
    switch (kind) {
      case 'N':
        set(memo, path, rhs)
        break
      case 'D':
        set(memo, path, undefined)
        break
      case 'E':
        set(memo, path, rhs)
        break
      case 'A': {
        get(memo, path)[index] = rhs
        break
      }
    }
    return memo
  }, {})
}

module.exports = objectDiff
