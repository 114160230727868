<template>
  <v-input
    v-model="value"
    :loading="loading"
    :readonly="readonly"
    :disabled="readonly"
    :rules="completenessRules({item, attribute, channels})">

    <tiptap-vuetify
      v-model="value"
      :extensions="extensions"
      :placeholder="label"
      :label="label"
      :readonly="readonly"
      :disabled="readonly"
      style="width: 100%"
      />
  </v-input>
</template>
<script>
import {mapGetters} from 'vuex'
import mixin from './attribute-editor-mixin'
import {
  TiptapVuetify,
  Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList,
  OrderedList, ListItem, Link, Blockquote, HardBreak,
  HorizontalRule, History}
  from 'tiptap-vuetify'

export default {
  props: ['item', 'attribute', 'label', 'readonly', 'loading', 'channels', 'marketId'],
  mixins: [mixin],
  components: {
    // VueEditor,
    TiptapVuetify
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    content: ''
  }),
  computed: {
    ...mapGetters(['completenessRules'])
  },
}
</script>
