<template>
  <v-card v-if="actionComponents">
    <v-card-text>
      <v-slide-group multiple show-arrows>
        <v-slide-item
          v-for="({action, component}) in actionComponents"
          :key="action.actionId"
          >
          <component
            :is="component"
            :action="action"
            :actionParameters="actionParameters"/>
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import InputActionDialog from './input-action-dialog.vue'
import UploadActionDialog from './upload-action-dialog.vue'

export default {
  props: ['actions', 'actionParameters'],
  computed: {
    ...mapGetters(['api', 'translate']),
    actionComponents () {
      const getActionComponent = (action) => {
        if (action.upload) {
          return {
            action,
            component: UploadActionDialog
          }
        }
        if (action.input) {
          return {
            action,
            component: InputActionDialog
          }
        }
      }
      return this.actions
        .map(getActionComponent)
    }
  }
}
</script>
