<template>
  <v-card>
    <v-card-title>
      {{translate('Reverse relations')}}
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="activeTab">
        <v-tab v-for="tab in tabs" :key="tab.relationId">
          {{reverseRelationsConfig.getReverseRelationLabel(tab.relationId)}} ({{tab.sources.length}})


        </v-tab>
        <v-tab-item v-for="tab in tabs" :key="tab.relationId">
          <relation-sources-table :reverseRelation="tab" />
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import RelationSourcesTable from './relation-sources-table.vue'
export default {
  props: ['reverseRelations'],
  data: () => ({activeTab: null}),
  components: {
    RelationSourcesTable
  },
  computed: {
    ...mapGetters(['translate', 'reverseRelationsConfig', 'translatereverseRelationName']),
    tabs () {
      return this.reverseRelationsConfig.sortReverseRelations(
        Object.values(this.reverseRelations))
    }
  }
}
</script>
