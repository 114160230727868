<template>
  <v-dialog
    :key="dz.id"
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn text v-on="on">
        {{action.title}}
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{action.title}}</v-toolbar-title>
        <v-spacer />
        <v-btn icon text @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        {{dz}} // {{action}}
        <vue-dropzone
          :id="dz.id"
          :key="dz.id"
          :options="dz.options"
          v-on:vdropzone-sending="dropzoneSending"
          v-on:vdropzone-complete="dropzoneComplete"
          v-on:vdropzone-error="dropzoneError">
        </vue-dropzone>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import {mapGetters} from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import parseUrl from 'url-parse'

const appendQuery = (url, query) => {
  let parsed = parseUrl(url, true)
  parsed.query = {
    ...query,
    ...parsed.query,
  }
  return parsed.toString()
}

export default {
  props: ['action', 'actionParameters'],
  data: () => ({
    dialog: false
  }),
  components: {
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapGetters(['notifications']),
    dz () {
      let {actionId, url, upload} = this.action
      let {accept} = upload || {}
      return {
        id: actionId,
        options: {
          url: appendQuery(url, this.actionParameters),
          thumbnailWidth: 150,
          acceptedFiles: accept || undefined,
          parallellUploads: 1
        }
      }
    }
  },
  methods: {
    dropzoneComplete () {
      this.$emit('action-complete')
    },
    dropzoneSending (/*file, xhr, formData*/) {
      // console.log({file})
      /*
      Object.entries(this.actionPostData || {})
        .forEach(([key, value]) => formData.append(key, value))
      // TODO: also set additional post data defined in action on server side
      */
    },
    dropzoneError (file) {
      this.notifications.emit('notification-message', {
        type: 'error',
        message: `Error uploading ${file.name}`
      })
    }
  }
}
</script>
