<template>
  <!--
  <simple-collection-table
    :collectionId="wizardModel.collectionId"
    :searchable="true"
    :selected="wizardModel.items" />
  -->
  <collection-table
    :loading="wizardModel.loading"
    :items="wizardModel.items"
    :schema="wizardModel.schema"
    :channels="wizardModel.channels"
    :relations="wizardModel.relations"
    :reverse-relations="wizardModel.reverseRelations"
    :suites="wizardModel.suites"
    :selected="wizardModel.selectedItems"
    :sortable="true"
    :searchable="true"
    />

</template>
<script>
import CollectionTable from '@/components/collections/collection-table.vue'
export default {
  props: ["wizardModel"],
  components: {
    CollectionTable
  }
}
</script>
