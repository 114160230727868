<template>
  <v-card v-if="preview">
    <v-card-title>{{translate('Preview')}}</v-card-title>
    <v-card-text>
      <div v-html="preview" style="overflow-x: auto; max-width: 100%"></div>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: ['itemData'],
  computed: {
    ...mapGetters(['collectionsConfig', 'translate']),
    preview: function () {
      let template = this.collectionsConfig.getCollectionItemTemplate(this.itemData.collectionId)
      if (template) {
        return template(this.itemData)
      }
      return ''
    }
  }
}
</script>
