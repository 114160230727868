import Vue from 'vue'
import {mapGetters} from 'vuex'

import get from 'object-get'

export default {
  data: () => ({
    initialValue: null,
    dirty: false
  }),
  computed: {
    ...mapGetters(['getValidMarket']),
    value: {
      get: function () {
        const {name, multimarket} = this.attribute
        if (multimarket) {
          const {marketId} = this.getValidMarket(this.marketId)
          return get(this.item, `${name}.${marketId}`)
        }
        return get(this.item, name)
      },
      set: function (v) {
        const {name, multimarket} = this.attribute
        if (multimarket) {
          const {marketId} = this.getValidMarket(this.marketId)
          if (!(name in this.item)) {
            Vue.set(this.item, name, {})
          }
          Vue.set(this.item[name], marketId, v)
        } else {
          Vue.set(this.item, name, v)
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        if (this.initialValue === null) {
          this.initialValue = {value: v}
        }
        this.dirty = this.initialValue.value !== v
      }
    }
  }
}
