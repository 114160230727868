<template>
  <v-text-field
    v-model="value"
    :readonly="readonly"
    :disabled="readonly" />
</template>
<script>
export default {
  props: ['table', 'readonly', 'rowIndex', 'cellIndex'],
  computed: {
    value: {
      get () { return this.table[this.rowIndex][this.cellIndex] },
      set (v) { this.table[this.rowIndex][this.cellIndex] = v }
    }
  }
}
</script>
