<template>
  <v-layout row>
    <v-flex sm6>
      <v-radio-group v-model="updateMode">
        <v-radio
          key="um1"
          :label="translate('Overwrite targets with selected features')"
          value="overwrite" />
        <v-radio
          key="um2"
          :label="translate('Complement targets with selected features')"
          value="complement" />
      </v-radio-group>
    </v-flex>
    <v-flex sm6>
      <v-layout column align-end justify-end style="height: 100%">
        <v-btn
          :disabled="!hasSelections"
          @click="copy"
          :loading="copying"
          >
          {{translate('Copy')}}
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: ['wizardModel'],
  data: () => ({
    updateMode: 'complement',
    copying: false
  }),
  computed: {
    ...mapGetters(['api', 'translate']),
    hasSelections () {
      if (Object.keys(this.wizardModel.selectedItems).length === 0) {
        return false
      }
      let features = this.wizardModel.selectedFeatures
      return features.attributes.some(({selected}) => selected)
        || features.relations.some(({selected}) => selected)
        || features.reverseRelations.some(({selected}) => selected)
        || features.suites.some(({selected}) => selected)
    },
    postData () {
      const overwrite = this.updateMode === 'overwrite'
      const {collectionId, item, selectedItems, selectedFeatures} = this.wizardModel
      const {key} = item
      const selectedNames = l => l.filter(({selected}) => selected).map(({name}) => name)
      return {
        collectionId: collectionId,
        sourceKey: key,
        targetKeys: Object.keys(selectedItems || {}),
        attributes: {
          overwrite,
          names: selectedNames(selectedFeatures.attributes)
        },
        relations: {
          overwrite,
          names: selectedNames(selectedFeatures.relations)
        },
        reverseRelations: {
          overwrite,
          names: selectedNames(selectedFeatures.reverseRelations)
        },
        suites: {
          overwrite,
          names: selectedNames(selectedFeatures.suites)
        }
      }
    }
  },
  methods: {
    async copy () {
      this.copying = true
      try {
        await this.api.recipes.complementCollectionItems({
          update: this.postData
        })
      } finally {
        this.copying = false
      }
    }
  }
}
</script>
