<template>
  <v-card>
    <v-card-title>
      {{translate('Network')}}
    </v-card-title>
    <v-card-text>
      <v-btn text
        v-if="selectedNode"
        :to="{name: 'collection-item', params: {collectionId: selectedNode.collectionId, itemKey: selectedNode.key }}">
        <v-icon small>fa fa-link</v-icon>&nbsp;{{selectedNode.key}} ({{collectionsConfig.getCollectionLabel(selectedNode.collectionId)}})
      </v-btn>
      <svg style="display:none;">
        <defs>
          <marker id="m-end" markerWidth="10" markerHeight="10" refX="9" refY="3" orient="auto" markerUnits="strokeWidth" >
            <path d="M0,0 L0,6 L9,3 z"></path>
          </marker>
          <marker id="m-start" markerWidth="6" markerHeight="6" refX="-4" refY="3" orient="auto" markerUnits="strokeWidth" >
            <!--<rect width="3" height="6"></rect>-->
          </marker>
        </defs>
      </svg>
      <d3-network
        :net-nodes="nodes"
        :net-links="links"
        :options="options"
        :link-cb="lcb"
        @node-click="selectNode"
         />
    </v-card-text>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import D3Network from 'vue-d3-network'
export default {
  props: ['collectionId', 'itemKey', 'network'],
  data: () => ({
    selectedNode: null
  }),
  components: {
    D3Network
  },
  mounted () {
    let {nodes = []} = this.network || {}
    this.selectedNode = nodes.find(({nodeId}) => nodeId === this.centerNodeId)
  },
  computed: {
    ...mapGetters(['collectionsConfig', 'translate']),
    centerNodeId () {
      let {nodes = []} = this.network || {}
      let {nodeId} = nodes.find(({collectionId, key}) => (collectionId === this.collectionId) && (key === this.itemKey)) || {}
      return nodeId
    },
    nodes () {
      let {nodes = []} = this.network || {}
      let focusNodeId = this.centerNodeId
      return nodes.map(({nodeId, collectionId, key}) => ({
        id: nodeId,
        name: `${key} (${this.collectionsConfig.getCollectionLabel(collectionId)})`,
        _color: (nodeId === focusNodeId) && '#aa0000'
      }))
    },
    links () {
      let {edges = []} = this.network || {}
      return edges.map(({sourceNodeId, targetNodeId, relationId}, index) => ({
        id: index,
        tid: targetNodeId,
        sid: sourceNodeId,
        name: relationId,
        _color: '#00aaff'
      }))
    },
    options () {
      return {
        force: 3000,
        // size:{ w:600, h:600},
        // nodeSize: this.nodeSize,
        nodeSize: 16,
        nodeLabels: true,
        // canvas: this.canvas,
        linkWidth:2
      }
    }
  },
  methods: {
    lcb (link) {
      link._svgAttrs = {
        'marker-end': 'url(#m-end)',
        'marker-start': 'url(#m-start)'
      }
      return link
    },
    selectNode (event, {id}) {
      let {nodes = []} = this.network || {}
      this.selectedNode = nodes.find(({nodeId}) => nodeId === id)
    }
  }
}
</script>
<style>
#m-end path, #m-start{
  fill: rgba(18, 120, 98, 0.8);
}
</style>
