<template>
  <v-text-field
    v-model="value"
    :loading="loading"
    :label="label"
    :readonly="readonly"
    :disabled="readonly"
    :type="htmlType"
    :outlined="dirty"
    :rules="completenessRules({item, attribute, channels})" />
</template>
<script>
import {mapGetters} from 'vuex'
import mixin from './attribute-editor-mixin'
export default {
  props: [
    'item', 'attribute', 'label', 'readonly', 'loading', 'channels', 'marketId',
    'item-access-path',
    'html-type'],
  mixins: [mixin],
  computed: {
    ...mapGetters(['completenessRules'])
  }
}
</script>
