<template>
  <v-data-table
    :headers="headers"
    :items="viableSuites"
    class="elevation-1">
    <template v-slot:top>
      <v-autocomplete
        v-model="selectedLabels"
        :label="translate('Search attribute')"
        :items="viableAttributes"
        item-text="label"
        item-key="name"
        multiple
      >
        <template v-slot:selection="{item}">
          <v-chip
            close
            @click:close="removeSelectedLabel(item)"
            >
            {{item.label}}
          </v-chip>
        </template>
      </v-autocomplete>
    </template>
    <template v-slot:item.select="{item}">
      <v-btn
        color="primary"
        @click="suiteSelected(item)"
        >
        <v-icon>add</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.suiteId="{item}">
      {{item.label}}
      <v-chip>{{item.size}}</v-chip>
    </template>
    <template v-slot:item.attributes="{item}">
      <v-chip v-for="({label}) in item.attributes" :key="label">{{label}}</v-chip>
    </template>
  </v-data-table>
</template>
<script>
import {mapGetters} from 'vuex'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'

const toMap = (list, keyFn, valueFn) => list.reduce((memo, item) => {
  memo[keyFn(item)] = valueFn(item)
  return memo
}, {})

export default {
  props: ['collectionId'],
  data: () => ({
    selectedLabels: []
  }),
  mounted () {
    this.selectedLabels.splice(0, this.selectedLabels.length)
  },
  computed: {
    ...mapGetters(['api', 'translate', 'suitesConfig']),
    headers () {
      return [{
        text: '',
        value: 'select',
        sortable: false
      }, {
        text: this.translate('Suite'),
        value: 'suiteId',
        sortable: true
      }, {
        text: this.translate('Attributes'),
        value: 'attributes',
        sortable: true
      }]
    },
    viableAttributes () {
      let attributes = this.viableSuites.map(({attributes}) => attributes)
      return sortBy(
        uniqBy(
          flatten(attributes),
          ({label}) => label
        ),
        ({label}) => label
      )
    },
    viableSuites () {
      let selectedLabels = this.selectedLabels
      return this.suites.suites
        .filter(({labels}) => selectedLabels.every(label => label in labels))
    },
    suites () {
      let {items, loading} = this.suiteSchemas
      let suites = items
        .map(suite => ({
          suiteId: suite.suiteId,
          label: this.suitesConfig.getSuiteLabel(suite.suiteId),
          size: suite.size,
          attributes: this.suitesConfig.mapSuitetableAttributes(suite)
            .filter(({attribute}) => attribute.name !== suite.key)
            .map(({label, attribute}) => ({label: label.trim(), attribute}))
            .filter(({label}) => label)
          }))
        .map(o => ({
          ...o,
          labels: toMap(o.attributes, ({label}) => label, () => true)
        }))
      return {
        loading,
        suites
      }
    }
  },
  asyncComputed: {
    suiteSchemas: {
      async get () {
        let {items} = await this.api.suites.getSuiteSchemas()
        return {
          items: items.filter(({collectionId}) => collectionId === this.collectionId),
          loading: false
        }
      },
      default: {
        loading: true,
        items: []
      }
    }
  },
  methods: {
    removeSelectedLabel ({label}) {
      let l = this.selectedLabels
      const index = l.indexOf(label)
      if (index >= 0) l.splice(index, 1)
    },
    suiteSelected (suite) {
      this.$emit('suite-selected', suite)
    }
  }
}
</script>
