<template>
  <v-layout align-center>
    <v-flex>
      <completeness-indicator
        v-for="({label, value}, index) in channelsConfig.mapFormCompleteness(itemData.completeness)"
        :value="value"
        :label="label"
        :key="index"
        />
    </v-flex>
  </v-layout>
</template>
<script>
import {mapGetters} from 'vuex'
import CompletenessIndicator from '@/components/completeness-indicator'

export default {
  props: ['itemData'],
  components: {
    CompletenessIndicator
  },
  computed: {
    ...mapGetters(['channelsConfig']),
  }
}
</script>
