<template>
  <v-card>
    <v-card-title>
      {{translate('Relations')}}
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="activeTab">
        <v-tab v-for="tab in tabs" :key="tab.relationId">
          {{relationsConfig.getRelationLabel(tab.relationId)}} ({{tab.targets.length}})
        </v-tab>
        <v-tab-item v-for="tab in tabs" :key="tab.relationId">
          <relation-targets-table :relation="tab" />
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import RelationTargetsTable from './relation-targets-table.vue'
export default {
  props: ['relations'],
  data: () => ({activeTab: null}),
  components: {
    RelationTargetsTable
  },
  computed: {
    ...mapGetters(['translate', 'relationsConfig', 'translateRelationName']),
    tabs () {
      return this.relationsConfig.sortRelations(
        Object.values(this.relations))
    }
  }
}
</script>
