<template>
  <v-checkbox
    v-model="value"
    :loading="loading"
    :label="label"
    :readonly="readonly"
    :disabled="readonly"
    :rules="completenessRules({item, attribute, channels})" />
</template>
<script>
import {mapGetters} from 'vuex'
import mixin from './attribute-editor-mixin'
export default {
  props: ['item', 'attribute', 'label', 'readonly', 'loading', 'channels', 'marketId'],
  mixins: [mixin],
  computed: {
    ...mapGetters(['completenessRules'])
  }
}
</script>
