<template>
      <v-form ref="validationForm">
        <v-tabs>
          <v-tab
            v-for="(group, index) in layoutGroups"
            :key="index">
              {{group.label}}
          </v-tab>
          <v-tab-item
            v-for="(group, index) in layoutGroups"
            :key="index">
            <slot name="tab-top" v-bind="{index}">
            </slot>
            <v-card>
              <v-card-text>
                <v-layout row fluid v-for="(row, index) in group.rows" :key="index">
                  <div v-for="({attribute, label, ro, w}, index) in row" :style="{flex: w}" :class="{'ml-2': index > 0}" :key="index">
                    <attribute-editor
                      v-if="!attribute.multimarket"
                      :loading="loading"
                      :item="item"
                      :attribute="attribute"
                      :label="label"
                      :readonly="ro"
                      :channels="channels"
                      />

                    <attribute-editor
                      v-if="attribute.multimarket"
                      :loading="loading"
                      :item="item"
                      :attribute="attribute"
                      :marketId="activeMarket.marketId"
                      :label="`${label} (${activeMarket.description})`"
                      :readonly="ro"
                      :channels="channels"
                      />

                    <!--
                    <attribute-editor
                      v-for="market in markets"
                      v-if="attribute.multimarket && (market.marketId === activeMarketId)"
                      :loading="loading"
                      :item="item"
                      :attribute="attribute"
                      :marketId="market.marketId"
                      :label="`${label} (${market.description})`"
                      :readonly="ro"
                      :channels="channels"
                      :key="market.marketId"
                      />
                      -->
                  </div>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-form>
</template>
<script>
import {mapGetters} from 'vuex'
import AttributeEditor from '@/components/attributes/attribute-editor'
export default {
  props: [
    'item',
    'collectionSchema',
    'loading',
    'channels',
    'show-all-markets'
  ],
  components: {
    AttributeEditor
  },
  computed: {
    ...mapGetters(['activeMarket', 'activeMarketId', 'markets', 'collectionFormLayout', 'collectionsConfig']),
    layoutGroups () {
      return this.collectionsConfig.mapFormLayout(this.collectionSchema)
    }
  },
  watch: {
    layoutGroups () {
      this.$nextTick(() => this.$refs.validationForm.validate())
    }
  }
}
</script>
