<template>
  <v-card>
    <v-card-text>
      <v-layout row fluid>
        <v-flex sm-3>
          <v-list dense>
            <v-subheader>
              <v-checkbox
                :input-value="attributesSelectionState.allSelected"
                :true-value="attributesSelectionState.allSelected"
                :key="attributesSelectionState.someSelected"
                :indeterminate="attributesSelectionState.someSelected"
                @change="toggleListSelection(selectedFeatures.attributes)"
                />
              {{translate('Attributes')}}
            </v-subheader>
            <v-list-item v-for="(f, i) in selectedFeatures.attributes" :key="i">
              <v-checkbox
                v-model="f.selected"
                :label="f.label" />
            </v-list-item>
          </v-list>
        </v-flex>

        <v-flex sm-3>
          <v-list dense>
            <v-subheader>
              <!--
              <v-checkbox
                :indeterminate="isIndeterminate(selectedFeatures.relations)"
                :value="allAreSelected(selectedFeatures.relations)"/>
                -->
                <v-checkbox
                  :input-value="relationsSelectionState.allSelected"
                  :true-value="relationsSelectionState.allSelected"
                  :key="relationsSelectionState.someSelected"
                  :indeterminate="relationsSelectionState.someSelected"
                  @change="toggleListSelection(selectedFeatures.relations)"
                  />
                {{translate('Relations')}}
            </v-subheader>
            <v-list-item v-for="(f, i) in selectedFeatures.relations" :key="i">
              <v-checkbox
                v-model="f.selected"
                :label="f.label" />
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex sm-3>
          <v-list dense>
            <v-subheader>
              <v-checkbox
                :input-value="reverseRelationsSelectionState.allSelected"
                :true-value="reverseRelationsSelectionState.allSelected"
                :key="reverseRelationsSelectionState.someSelected"
                :indeterminate="reverseRelationsSelectionState.someSelected"
                @change="toggleListSelection(selectedFeatures.reverseRelations)"
                />
              {{translate('Reverse Relations')}}
            </v-subheader>
            <v-list-item v-for="(f, i) in selectedFeatures.reverseRelations" :key="i">
              <v-checkbox
                v-model="f.selected"
                :label="f.label" />
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex sm-3>
          <v-list dense>
            <v-subheader>
              <v-checkbox
                :input-value="suitesSelectionState.allSelected"
                :true-value="suitesSelectionState.allSelected"
                :key="suitesSelectionState.someSelected"
                :indeterminate="suitesSelectionState.someSelected"
                @change="toggleListSelection(selectedFeatures.suites)"
                />
              {{translate('Suites')}}
            </v-subheader>
            <v-list-item v-for="(f, i) in selectedFeatures.suites" :key="i">
              <v-checkbox
                v-model="f.selected"
                :label="f.label" />
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  props: ["wizardModel"],
  computed: {
    ...mapGetters(['translate', 'collectionsConfig', 'relationsConfig', 'reverseRelationsConfig']),
    selectedFeatures () { return this.wizardModel.selectedFeatures },

    attributesSelectionState () {
      return this.getSelectionState(this.wizardModel.selectedFeatures.attributes)
    },
    relationsSelectionState () {
      return this.getSelectionState(this.wizardModel.selectedFeatures.relations)
    },
    reverseRelationsSelectionState () {
      return this.getSelectionState(this.wizardModel.selectedFeatures.reverseRelations)
    },
    suitesSelectionState () {
      return this.getSelectionState(this.wizardModel.selectedFeatures.suites)
    }
  },
  methods: {
    getSelectionState (list) {
      let allSelected = list.every(({selected}) => selected)
      let noneSelected = list.every(({selected}) => !selected)
      return {
        allSelected,
        someSelected: !(allSelected || noneSelected)
      }
    },
    toggleListSelection (list) {
      let selected = list.every(({selected}) => selected)

      list.forEach(item => item.selected = !selected)
    }
  }

}
</script>
