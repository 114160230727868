<template>
  <v-dialog
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn text v-on="on">
        {{action.title}}
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{action.title}}</v-toolbar-title>
        <v-spacer />
        <v-btn text @click="saveAction()">
          <v-icon>save</v-icon>
        </v-btn>

        <v-btn icon text @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        {{action}}
        <component
          v-for="({component, field}, index) in inputComponents"
          :ref="`input_component_${index}`"
          :is="component"
          :field="field"
          :formData="formData"
          :key="index" />
        <form ref="form">
        </form>
        {{debugFormData}}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import FileInput from './input/file-input.vue'
const inputFactory = {
  '?': () => null,
  file: (field) => ({
    field,
    component: FileInput
  })
}

export default {
  props: ['action'],
  data: () => ({
    dialog: false,
    formData: null,
    mutations: 1
  }),
  mounted () {
    this.formData = new FormData(this.$refs.form)
  },
  computed: {
    debugFormData () {
      return this.mutations && this.formData && Array.from(this.formData.entries())
    },
    inputComponents () {
      let fields = this.action && this.action.input && this.action.input.fields
      return (fields || [])
        .map((field) => (inputFactory[field.type] || inputFactory['?'])(field))
        .filter(component => component)
    }
  },
  methods: {
    saveAction () {
      // loop over inputs by their refs
      Object.values(this.$refs).forEach(() => {})
    }
  }


}
</script>
