<!--
  https://css-tricks.com/drag-and-drop-file-uploading/#targetText=%23Drag%20'n'%20Drop&targetText=This%20part%20deals%20with%20adding,files%20when%20they%20are%20dropped.&targetText=e.preventDefault()%20and%20e,the%20assigned%20events%20across%20browsers.
-->
<template>
  <v-card
    style="min-height:400px"
    :dark="dragOver"
    flat
    @drag.stop.prevent="nop"
    @dragstart.stop.prevent="nop"
    @dragend.stop.prevent="handleDragOver(false)"
    @dragover.stop.prevent="handleDragOver(true)"
    @dragenter.stop.prevent="handleDragOver(true)"
    @dragleave.stop.prevent="handleDragOver(false)"
    @drop.stop.prevent="handleDroppedFiles"
  >
    <v-card-title>Drag files here</v-card-title>
    <v-card-text>
      {{files}}
    </v-card-text>
</v-card>


</template>
<script>
export default {
  props: ['field', 'formData'],
  components: {
  },
  data: () => ({
    dragOver: false,
    files: []
  }),
  mounted () {
    // this.formData.append('apa', 'hej hej')
  },
  computed: {
    isBrowserSupported () {
      let div = document.createElement('div')
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && ('FormData' in window) && ('FileReader' in window)
    },

  },
  methods: {
    nop () {},
    handleDragOver (dragOver) {
      this.dragOver = dragOver
    },
    handleDroppedFiles (event) {
      this.dragOver = false
      this.files = event.dataTransfer.files
    }
  }
}
</script>
