<template>
  <v-sheet>
  <v-card v-for="({suiteId, label, item, mappedAttributes}) in editGroups" :key="suiteId">
    <v-card-title>{{label}}</v-card-title>

    <v-card-text flat>
      <v-layout row fluid>
        <v-flex>
          <v-layout>
            <v-flex
              v-for="({attribute, label}, index) in mappedAttributes"
              :class="{'ml-2': index > 0}"
              :key="index"
              >
              <!--
              <attribute-editor
                :loading="loading"
                :item="item"
                :attribute="ma.attribute"
                :name="ma.label"
                :label="ma.label"
                :item-access-path="`suites.${suiteId}`"
                />
              -->
              <attribute-editor
                :loading="loading"
                :item="item"
                :attribute="attribute"
                :label="attribute.multimarket ? `${label} (${activeMarket.description})` : label"
                :item-access-path="`suites.${suiteId}`"
                />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-spacer />
        <confirm-button v-if="allowDelete">
          <template v-slot:button="confirm">
            <v-btn class="mr-2" small color="warning" @click="confirm">
              <v-icon>delete</v-icon> {{translate('Delete')}}
            </v-btn>
          </template>
          <template v-slot:confirm="cancel">
            <v-item-group multiple>
              <v-btn class="mr-2" small color="error" @click="deleteSuite(suiteId)">
                <v-icon>delete</v-icon> {{translate('Delete this suite?', 'remove.suite')}}
              </v-btn>
              <v-btn class="mr-2" small icon @click="cancel">
                <v-icon>cancel</v-icon>
              </v-btn>
            </v-item-group>
          </template>
        </confirm-button>
        <v-btn
          class="mr-2"
          :to="`/suite/${suiteId}`"
          color="primary"
          small>
          <v-icon>fa-eye</v-icon>&nbsp;{{translate('View similar')}}
        </v-btn>
        <v-btn
          class="mr-2"
          :to="`/suite-schema/${suiteId}`"
          color="primary"
          small>
          <v-icon>edit</v-icon>&nbsp;{{translate('Edit schema')}}
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</v-sheet>
</template>
<script>
import {mapGetters} from 'vuex'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

import AttributeEditor from '@/components/attributes/attribute-editor.vue'
import ConfirmButton from '@/components/confirm-button.vue'
export default {
  props: ['suites', 'loading', 'allowDelete'],
  components: {
    AttributeEditor,
    ConfirmButton
  },
  computed: {
    ...mapGetters(['activeMarket', 'translate', 'api', 'suitesConfig']),
    editGroups () {
      return map(this.suites, (item, suiteId) => ({
        suiteId,
        label: this.suitesConfig.getSuiteLabel(suiteId),
        item,
        suiteSchema: this.suiteSchemasBySuiteId[suiteId]
      }))
      .filter(({suiteSchema}) => suiteSchema)
      .map(g => ({
        ...g,
        mappedAttributes: Object.values(this.suitesConfig.mapSuiteSchema(g.suiteSchema).attributes)
          .filter(({key}) => !key)
      }))
    }
  },
  asyncComputed: {
    suiteSchemasBySuiteId: {
      async get () {
        let schemas = await this.api.suites.getSuiteSchemas()
        return keyBy(schemas.items, 'suiteId')
      },
      default: {}
    }
  },
  methods: {
    deleteSuite(suiteId) {
      this.$emit('delete-suite', {suiteId})
    }
  }
}
</script>
