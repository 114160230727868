<template>
  <v-expansion-panels
    :popout="true"
    :multiple="true"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>{{translate('Select features')}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <wizard-step-select-features :wizardModel="wizardModel" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{translate('Select targets')}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <wizard-step-select-items :wizardModel="wizardModel" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{translate('Copy')}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <wizard-step-finalize :wizardModel="wizardModel" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import {mapGetters} from 'vuex'
import WizardStepFinalize from './wizard-step-finalize.vue'
import WizardStepSelectItems from './wizard-step-select-items.vue'
import WizardStepSelectFeatures from './wizard-step-select-features.vue'

export default {
  props: ['collectionId', 'item'],
  data: () => ({
    dialog: false,
    step: 0,
    mutations: 1
  }),
  components: {
    WizardStepFinalize,
    WizardStepSelectItems,
    WizardStepSelectFeatures
  },
  computed: {
    ...mapGetters(['api', 'translate', 'collectionsConfig', 'relationsConfig', 'reverseRelationsConfig'])
  },
  asyncComputed: {
    wizardModel: {
      async get () {
        let loadAttributes = this.mutations && this.collectionsConfig.getTableAttributesForLoading(this.collectionId)
        let cd = await this.api.collections.getCollection({collectionId: this.collectionId, query: {
          attributes: loadAttributes && loadAttributes.join(',')
        }})
        return {
          collectionId: this.collectionId,
          item: this.item,
          selectedItems: {},
          selectedFeatures: {
            attributes: this.collectionsConfig.mapCollectionAttributes(cd.schema).filter(({name}) => name != cd.schema.key).map(o => ({...o, selected: false})),
            relations: this.relationsConfig.mapRelations(Object.values(cd.relations)).map(o => ({...o, name: o.relationId, selected: true})),
            reverseRelations: this.reverseRelationsConfig.mapReverseRelations(Object.values(cd.reverseRelations)).map(o => ({...o, name: o.relationId, selected: true})),
            suites: Object.keys((this.item && this.item.suites) || {}).map(suiteId => ({suiteId, name: suiteId, label: suiteId, selected: true}))
          },
          ...cd
        }
      },
      default: {
        loading: true,
        collectionId: null,
        item: null,
        selectedItems: {},
        selectedFeatures: {
          attributes: [],
          relations: [],
          reverseRelations: [],
          suites: []
        },
        items: [],
        schema: {},
        channels: {},
        relations: {},
        reverseRelations: {},
        suites: {}
      }
    }
  }
}
</script>
