var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',_vm._l((_vm.editGroups),function(ref){
var suiteId = ref.suiteId;
var label = ref.label;
var item = ref.item;
var mappedAttributes = ref.mappedAttributes;
return _c('v-card',{key:suiteId},[_c('v-card-title',[_vm._v(_vm._s(label))]),_c('v-card-text',{attrs:{"flat":""}},[_c('v-layout',{attrs:{"row":"","fluid":""}},[_c('v-flex',[_c('v-layout',_vm._l((mappedAttributes),function(ref,index){
var attribute = ref.attribute;
var label = ref.label;
return _c('v-flex',{key:index,class:{'ml-2': index > 0}},[_c('attribute-editor',{attrs:{"loading":_vm.loading,"item":item,"attribute":attribute,"label":attribute.multimarket ? (label + " (" + (_vm.activeMarket.description) + ")") : label,"item-access-path":("suites." + suiteId)}})],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.allowDelete)?_c('confirm-button',{scopedSlots:_vm._u([{key:"button",fn:function(confirm){return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"warning"},on:{"click":confirm}},[_c('v-icon',[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.translate('Delete'))+" ")],1)]}},{key:"confirm",fn:function(cancel){return [_c('v-item-group',{attrs:{"multiple":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteSuite(suiteId)}}},[_c('v-icon',[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.translate('Delete this suite?', 'remove.suite'))+" ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":cancel}},[_c('v-icon',[_vm._v("cancel")])],1)],1)]}}],null,true)}):_vm._e(),_c('v-btn',{staticClass:"mr-2",attrs:{"to":("/suite/" + suiteId),"color":"primary","small":""}},[_c('v-icon',[_vm._v("fa-eye")]),_vm._v(" "+_vm._s(_vm.translate('View similar'))+" ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"to":("/suite-schema/" + suiteId),"color":"primary","small":""}},[_c('v-icon',[_vm._v("edit")]),_vm._v(" "+_vm._s(_vm.translate('Edit schema'))+" ")],1)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }