<template>
  <component
    :is="editorComponent"
    v-bind="editorProps"
    />
</template>
<script>
import TextFieldAttributeEditor from './text-field-attribute-editor.vue'
import AttributeEditorForBoolean from './attribute-editor-for-boolean'
import AttributeEditorForHtml from './attribute-editor-for-html'
import AttributeEditorForText from './attribute-editor-for-text'
import AttributeEditorForTable from './attribute-editor-for-table'

const component = (component, props) => ({
  component,
  props: props || {}
})

const textComponent = htmlType => component(TextFieldAttributeEditor, {htmlType})

const typeMappings = {
  'string': textComponent('text'),
  'text': component(AttributeEditorForText),
  'html': component(AttributeEditorForHtml),
  'integer': textComponent('number'),
  'number': textComponent('number'),
  'date': textComponent('date'),
  'image': textComponent('url'),
  'url': textComponent('url'),
  'boolean': component(AttributeEditorForBoolean),
  'table': component(AttributeEditorForTable)
}

export default {
  props: [
    'loading',
    'item', 'attribute', 'label', 'readonly', 'channels', 'marketId',
    'item-access-path'
  ],
  data: () => ({}),
  computed: {
    editorComponent () {
      return typeMappings[this.attribute.type].component
    },
    editorProps () {
      return {
        loading: this.loading,
        item: this.item,
        attribute: this.attribute,
        label: this.label || this.attribute.name,
        readonly: this.readonly || this.attribute.key,
        channels: this.channels || {},
        marketId: this.marketId,
        itemAccessPath: this.itemAccessPath || 'item',
        ...typeMappings[this.attribute.type].props
      }
    },

    componentType() {
      return typeMappings[this.attribute.type]
    }
  }
}
</script>
