<template>
  <v-chip
    v-if="(value >= 0) && (value <= 1)"
    :color="color"
    text-color="white"
    class="completeness-indicator"
    >
    {{ label }} {{valueFormatted}}%
  </v-chip>
</template>
<script>

const colors = ['green', 'yellow', 'amber', 'orange', 'red']

export default {
  props: ['label', 'value'],
  computed: {
    color () {
      let index = Math.min(colors.length-1, Math.ceil(colors.length * (1 - this.value)))
      return colors[index]
    },
    valueFormatted () {
      return Math.round(this.value * 100)
    }
  }
}
</script>
