var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('collection-table',{attrs:{"items":_vm.relation.targets,"schema":_vm.relation.targetCollectionSchema,"show-suite-summary":true},scopedSlots:_vm._u([{key:"edit-item",fn:function(ref){
var item = ref.item;
var schema = ref.schema;
return [_c('v-btn',{attrs:{"to":{name: 'collection-item', params: {collectionId: schema.collectionId, itemKey: item.key}},"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"delete-item",fn:function(ref){
var item = ref.item;
var items = ref.items;
var schema = ref.schema;
return [_c('confirm-button',{scopedSlots:_vm._u([{key:"button",fn:function(confirm){return [_c('v-btn',{attrs:{"text":"","small":"","icon":"","color":"warning"},on:{"click":confirm}},[_c('v-icon',[_vm._v("delete")])],1)]}},{key:"confirm",fn:function(cancel){return [_c('v-item-group',{attrs:{"multiple":""}},[_c('v-btn',{attrs:{"text":"","small":"","color":"error"},on:{"click":function($event){return _vm.unreferenceItem({item: item, items: items, schema: schema})}}},[_c('v-icon',[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.translate('Unreference this item?', 'remove.relation.item'))+" ")],1),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":cancel}},[_c('v-icon',[_vm._v("cancel")])],1)],1)]}}],null,true)})]}},{key:"top",fn:function(){return [_c('div',{staticClass:"mt-1"},[_c('v-btn',{attrs:{"small":"","color":"primary","to":{name: 'relation', params: {relationId: _vm.relation.relationId, sourceKey: _vm.relation.sourceKey}}}},[_vm._v(" "+_vm._s(_vm.translate('Edit relation', 'relation.edit'))+" ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }