<template>
  <v-dialog
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn text v-on="on" :loading="loading">
        <v-icon>fa-copy</v-icon>&nbsp;{{translate('Copy', 'copy')}}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{translate('Copy', 'copy')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn text icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <copy-item-wizard :collectionId="collectionId" :item="item" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
import CopyItemWizard from './copy-item-wizard'
export default {
  props: ['loading', 'collectionId', 'item'],
  data: () => ({
    dialog: false
  }),
  components: {
    CopyItemWizard
  },
  computed: {
    ...mapGetters(['translate'])
  }
}
</script>
