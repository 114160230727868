var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.viableSuites},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-autocomplete',{attrs:{"label":_vm.translate('Search attribute'),"items":_vm.viableAttributes,"item-text":"label","item-key":"name","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.removeSelectedLabel(item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])]}}]),model:{value:(_vm.selectedLabels),callback:function ($$v) {_vm.selectedLabels=$$v},expression:"selectedLabels"}})]},proxy:true},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.suiteSelected(item)}}},[_c('v-icon',[_vm._v("add")])],1)]}},{key:"item.suiteId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" "),_c('v-chip',[_vm._v(_vm._s(item.size))])]}},{key:"item.attributes",fn:function(ref){
var item = ref.item;
return _vm._l((item.attributes),function(ref){
var label = ref.label;
return _c('v-chip',{key:label},[_vm._v(_vm._s(label))])})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }