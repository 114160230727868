<template>
  <layout-page
    :page-description="pageDescription"
    :right-drawer="drawerActions.length"
    :extended="false"
    >
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{details.key}} - {{collectionsConfig.getCollectionLabel(details.collectionId)}}
      </v-toolbar-title>
      <v-toolbar-title>
        <item-completeness :itemData="details" />
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <pick-suite-button
          :key="`psb-${viewKey}`"
          :loading="details.loading"
          :collectionId="details.collectionId"
          @suite-selected="addSuite"
        />
        <copy-item-button
          :key="`cib-${viewKey}`"
          :loading="details.loading"
          :collectionId="collectionId"
          :item="details"
        />

        <v-btn
          :key="`save-${viewKey}`"
          text
          @click="updateItem"
          :disabled="!hasChanges"
          >
          <v-icon>save</v-icon>&nbsp;{{translate('Save', 'save')}}
        </v-btn>
      </v-toolbar-items>
    </template>
    <template v-slot:drawer>
      <actions-list
        :actions="drawerActions"
        :actionParameters="actionParameters"
        v-if="!details.loading"
        />
    </template>

    <v-sheet class="enrichment-sheet" v-if="details.loading">
      <v-skeleton-loader
        type="card, table" />
    </v-sheet>

    <v-sheet class="enrichment-sheet" v-if="!details.loading">
      <item-preview :itemData="details" :collectionSchema="details.collectionSchema"/>
    </v-sheet>
    <actions-toolbar v-if="dialogActions.length && !details.loading"
      :actions="dialogActions"
      :actionParameters="actionParameters"
      />
    <v-sheet class="enrichment-sheet" v-if="!details.loading">
<!--
      <v-card>
        <v-card-text><pre><code>{{JSON.stringify(getItemChanges(), null, 2)}}</code></pre></v-card-text>
      </v-card>
-->
      <attributes-editor
        :loading="details.loading"
        :item="details.item"
        :collectionSchema="details.collectionSchema"
        :channels="details.channels"
        :show-all-markets="true"
        >
      </attributes-editor>
    </v-sheet>
    <v-sheet class="enrichment-sheet" v-if="!details.loading && Object.keys(details.suites).length">
      <suites-editor
        :loading="details.loading"
        :suites="details.suites"
        :collectionSchema="details.collectionSchema"
        :allow-delete="true"
        @delete-suite="removeSuite"
        />
    </v-sheet>

    <v-sheet class="enrichment-sheet" v-if="!details.loading && hasKeys(details.relations)">
      <relations-editor :relations="details.relations" v-if="hasKeys(details.relations)"/>
    </v-sheet>

    <v-sheet class="enrichment-sheet" v-if="!details.loading && hasKeys(details.reverseRelations)">
      <reverse-relations-editor :reverseRelations="details.reverseRelations" v-if="hasKeys(details.reverseRelations)"/>
    </v-sheet>
    <v-sheet class="enrichment-sheet" v-if="!details.loading && details.sourceNetwork">
      <item-network :network="details.sourceNetwork" :collectionId="collectionId" :itemKey="itemKey"/>
    </v-sheet>
  </layout-page>
</template>
<script>
import Vue from 'vue'

import LayoutPage from '@/components/layout/layout-page.vue'
import ActionsToolbar from '@/components/actions/actions-toolbar'
import AttributesEditor from './attributes/attributes-editor'
// import CopyItemWizardDialogButton from './copy-item-wizard/copy-item-wizard-dialog-button.vue'

import ItemCompleteness from './completeness/item-completeness'
import ItemNetwork from './network/item-network'
import ItemPreview from './preview/item-preview'
import RelationsEditor from './relations/relations-editor'
import reverseRelationsEditor from './relations/reverse-relations-editor'
import SuitesEditor from './suites/suites-editor'
import ActionsList from '@/components/actions/actions-list'

import CopyItemButton from './copy-item-wizard/copy-item-button'
import PickSuiteButton from '@/components/suites/pick-suite-button.vue'

import {mapGetters} from 'vuex'

import get from 'lodash/get'
// import set from 'lodash/set'

// import debounce from 'lodash/debounce'
import objectDiff from '@/lib/object-diff.js'
const clone = o => JSON.parse(JSON.stringify(o))

export default {
  props: ['collectionId', 'itemKey'],
  data: () => ({
    mutations: 1,
    //hasChanges: false,
    dialogs: {
      addSuite: false
    }
  }),
  components: {
    LayoutPage,
    ActionsToolbar,
    AttributesEditor,
    CopyItemButton,
    ItemCompleteness,
    ItemNetwork,
    ItemPreview,
    PickSuiteButton,
    RelationsEditor,
    reverseRelationsEditor,
    SuitesEditor,
    ActionsList
  },
  /*
  watch: {
    details: {
      deep: true,
      handler: debounce(function () {
        this.hasChanges = this.getItemChanges() || this.getSuitesChanges()
      }, 500)
    }
  },
  */
  computed: {
    ...mapGetters(['collectionsConfig', 'api', 'translate', 'createPageDescription']),
    hasChanges () {
      return this.getItemChanges() || this.getSuitesChanges()
    },
    viewKey () {
      return [this.collectionId, this.itemKey].join(':')
    },
    // data to be posted with each action endpoint
    actionParameters () {
      return {
        collectionId: this.collectionId,
        itemKey: this.itemKey
      }
    },
    // actions that should be accessible through buttons
    dialogActions () {
      // return this.actions.filter(action => !get(action, 'ui.important'))
      return []
    },
    // actions that should be directly accessible in right drawer
    drawerActions () {
      return this.actions.filter(action => get(action, 'ui.important'))
    },
    pageDescription () {
      return this.createPageDescription()
        .addCollection(this)
        .addCollectionItem(this)
    }
  },
  asyncComputed: {
    actions: {
      async get () {
        let allActions = await this.api.actions.getActions({cached: true})
        return allActions.filter(action => get(action, `scope.collection-item.${this.collectionId}`) === true)
      },
      default: []
    },
    details: {
      async get () {
        let details = this.mutations && await this.api.collections.getCollectionItem({collectionId: this.collectionId, itemKey: this.itemKey})

        // we save original copies of item and suites so we can detect changes for minimal saves later
        return {
          ...details,
          initial: {
            item: clone(details.item),
            suites: clone(details.suites)
          }
        }
      },
      default () {
        return {
          loading: true,
          collectionId: this.collectionId,
          key: this.itemKey,
          item: {},
          collectionSchema: {collectionId: this.collectionId, attributes: {}},
          completeness: {},
          channels: {},
          relations: {},
          reverseRelations: {},
          suites: {},

          initial: {item: null, suites: null}
        }
      }
    }
  },
  methods: {
    // calculate what has changed in details.item
    getItemChanges () {
      let {item, initial: {item: initialItem}} = this.details
      return objectDiff(initialItem, item)
    },
    // calculate what has changed in details.suites
    getSuitesChanges() {
      let {suites, initial: {suites: initialSuites}} = this.details
      return objectDiff(initialSuites, suites)
    },
    hasKeys (map) {
      return Object.keys(map).length > 0
    },
    // predicate for filtering out wich actions shoud be accessible to an item
    // in this collection
    filterAction (action) {
      return get(action, 'scope.collection-item') === this.collectionId
    },
    async updateItem () {
      // We can not rely on calculated this.changes since it's debounced and
      // easily comes out of sync time-wise
      let item = this.getItemChanges()
      let suites = this.getSuitesChanges()
      let {key, collectionId} = this.details

      /* eslint-disable no-console */
      console.log({
        update: {
          item, suites
        }
      })
      /* eslint-enable no-console */

      // first: update suites
      suites && await this.api.suites.updateCollectionItem({
          update: {
            key,
            collectionId,
            overwrite: true,
            suites
          }
        })
      // second: update item
      item && await this.api.collections.updateCollection({
        update: {
          collectionId,
          overwrite: true,
          items: [{
            ...item,
            [this.details.collectionSchema.key]: key
          }]
        }
      })
      // update mutation count to trigger a reload
      this.mutations = this.mutations + 1
    },
    addSuite ({suiteId}) {
      if (!this.details.suites[suiteId]) {
        Vue.set(this.details.suites, suiteId, {})
      }
    },
    removeSuite ({suiteId}) {
      Vue.delete(this.details.suites, suiteId)
    }
  }
}
</script>
<style>
  .enrichment-sheet {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
  }
</style>
