<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn text v-on="on" :loading="loading">
        <v-icon>edit</v-icon> {{translate('Add suite')}}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{translate('Add suite')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn text icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <suite-picker @suite-selected="suiteSelected" :collectionId="collectionId"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
import SuitePicker from './suite-picker.vue'

export default {
  props: ['loading', 'collectionId'],
  data: () => ({
    dialog: false,
  }),
  components: {
    SuitePicker
  },
  computed: {
    ...mapGetters(['translate'])
  },
  methods: {
    suiteSelected (suite) {
      this.dialog = false
      this.$emit('suite-selected', suite)
    }
  }
}
</script>
