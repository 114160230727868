<template>
  <collection-table
    :items="reverseRelation.sources"
    :schema="reverseRelation.sourceCollectionSchema"
    :sortable="true"
    :show-suite-summary="true"
    >
    <template v-slot:edit-item="{item, schema}">
      <v-btn :to="{name: 'collection-item', params: {collectionId: schema.collectionId, itemKey: item.key}}" small icon color="primary">
        <v-icon>edit</v-icon>
      </v-btn>
    </template>
    <template v-slot:delete-item="{item, items, schema}">
      <confirm-button>
        <template v-slot:button="confirm">
          <v-btn text small icon color="warning" @click="confirm">
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:confirm="cancel">
          <v-item-group multiple>
            <v-btn text small color="error" @click="unreferenceItem({item, items, schema})">
              <v-icon>delete</v-icon> {{translate('Unreference this item?', 'remove.relation.item')}}
            </v-btn>
            <v-btn text small icon @click="cancel">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-item-group>
        </template>
      </confirm-button>
    </template>
    <template v-slot:top>
      <div class="mt-1">
        <v-btn small color="primary"
          :to="{name: 'reverse-relation', params: {relationId: reverseRelation.relationId, targetKey: reverseRelation.targetKey}}">
            {{translate('Edit back relation', 'reverse-relation.edit')}}
        </v-btn>
      </div>
    </template>
  </collection-table>
</template>
<script>
import {mapGetters} from 'vuex'
import CollectionTable from '@/components/collections/collection-table.vue'
import ConfirmButton from '@/components/confirm-button.vue'
export default {
  props: ['reverseRelation'],
  components: {
    CollectionTable,
    ConfirmButton
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'translate'])
  },
  methods: {
    async unreferenceItem ({item/*, schema*/}) {
      let list = this.reverseRelation.sources
      let index = list.indexOf(item)
      if (index >= 0) {
        let {relationId , targetKey} = this.reverseRelation
        let {key} = item
        let update = [
          {
            relationId,
            sourceKey: key,
            targets: [{key: targetKey}]
          }
        ]
        await this.api.relations.unlinkRelation({
          update
        })
        list.splice(index, 1)
      }
    }
  }
}
</script>
