<template>
  <v-simple-table
    v-sortable-table="'.drag-handle'"
    >
    <tbody>
      <tr v-for="(row, ri) in value" :key="ri">
        <td class="drag-handle" style="width:1%">
          <v-icon>swap_vert</v-icon>
        </td>
        <td v-for="(cell, ci) in row" :key="ci">
          <table-cell-editor :table="value" :rowIndex="ri" :cellIndex="ci" :readonly="readonly" />
          <!--
          <v-text-field
            v-bind="value[ri][ci]"
            :readonly="readonly"
            :disabled="readonly" />
          -->
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
import {mapGetters} from 'vuex'
import mixin from './attribute-editor-mixin'
import SortableTable from '@/directives/sortable-table'
import TableCellEditor from './table-cell-editor.vue'

const isArray = array => Array.isArray(array)
const isMatrix = matrix =>
  isArray(matrix)
  && matrix.every(row => isArray(row))
  && ((matrix.length === 0) || (matrix.every(row => row.length === matrix[0].length)))
const padArray = (array, length, value) => {
  while (array.length < length) { array.push(value) }
  return array
}

export default {
  props: ['item', 'attribute', 'label', 'readonly', 'channels', 'marketId'],
  mixins: [mixin],
  components: {
    TableCellEditor
  },
  directives: {
    SortableTable
  },
  mounted () {
    // Check if we have a perfect M x N matrix
    if (isMatrix(this.value)) { return }

    let matrix = this.value
    if (!isArray(matrix)) { matrix = [] }

    matrix = matrix.filter(isArray)
    let cols = matrix.reduce((c, row) => Math.max(c, row.length), 0)

    matrix = matrix.map(row => padArray(row, cols, null))

    this.value = matrix
  },
  computed: {
    ...mapGetters(['translate', 'completenessRules']),
    columnCount () {
      let v = this.value
      return (v && v.length) || 0
    }
  }
}
</script>
